import React from "react";
import styled from "styled-components/macro";

const Logo = styled.svg`
  width: 300px;
  cursor: pointer;

  @media (max-width: 667px) {
    width: 100%;
  }
`;

export const HeaderLogo = ({ onMouseEnter, onClick }) => {
  return (
    <Logo
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1079.64 200.12"
    >
      <path
        d="M87.35,157.81H51.67l3.54-14.4c-7.53,9.31-16,16.4-28.59,16.4-15.07,0-25.26-9.31-25.26-24.38,0-7.31,3.77-24.6,6.43-35.68l7.09-28.81c6.2-25.26,17.06-34.79,35-34.79,10.41,0,22.38,4.88,26.37,15.29L87.79,1.35h35.68ZM70.72,75.15a30.1,30.1,0,0,0,.89-6.21c0-5.76-3.55-9.09-9.31-9.09-8.87,0-11.3,8.2-12.85,15.29L39.26,119.46c-.66,3.1-1.55,6.21-1.55,9.53,0,5.54,3.77,8.64,9.09,8.64,9.31,0,11.53-9.31,13.3-16.62Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M159.6,39l-5.54,23.71c8.87-17.51,16.4-22.6,35.68-25.71l-8.87,32.8h-7.31c-21.72,0-23.93,12.19-28.14,30.14l-13.3,57.84h-35L124.58,39Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M214.78,106.84c-1.77,7.09-4,14.85-4,21.72,0,6,5.32,8,10.41,8,9.75,0,11.75-7.53,13.74-17.06h35.9c-6,33.91-26.82,41.44-51.19,41.44-22.38,0-43-7.31-43-31.47,0-11.08,7.53-48.75,15.07-68.7,6.87-18.17,25.71-24.6,44.54-24.6,19.72,0,43,4.65,43,28.81,0,8-4.65,28.59-7.76,41.88Zm27.7-21.72c1.11-5.32,3.1-12.85,3.1-16.18,0-6.43-4-9.09-10-9.09-12.63,0-13.07,11.52-16,25.26h22.83Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M302.54,167.56c-6.87,29.7-14.85,33.91-44.77,33.91H244l5.32-23.27h3.1c8.64,0,12.63-.66,15.29-11.75L297.22,39h35ZM334.9,27.73h-35l6.2-26.37h34.79Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M333.79,76c5.1-20.61,7.76-27.48,18.62-33,8.2-4.21,19.5-6.21,31.91-6.21,20.39,0,41.44,5.1,41.44,25.93a55.69,55.69,0,0,1-2,13.3l-15.51,67.15a34.55,34.55,0,0,0-.89,6.43c0,5.32,1.77,6.87,3.32,8.2H370.36l3.1-12.41c-8,6.87-17.95,15.51-31.91,15.51-2.44,0-23.49,0-23.49-21.5,0-7.31,4.65-28.14,12-36.57s18-11.52,59.39-23.49c1.11-4.65,2.66-12.19,2.66-14,0-6.21-5.76-6.65-9.53-6.65-10,0-11.75,5.32-15.07,17.29Zm50.53,26.15c-8,2.44-18.17,5.54-22.38,8.86-5.76,4.65-7.31,17.29-7.31,19.5,0,4,1.77,7.31,7.76,7.31,6.21,0,10.86-3.77,14.63-7.09Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M505.54,157.81H469.86l3.55-14.4c-7.53,9.31-16,16.4-28.59,16.4-15.07,0-25.26-9.31-25.26-24.38,0-7.31,3.77-24.6,6.43-35.68l7.09-28.81c6.21-25.26,17.07-34.79,35-34.79,10.42,0,22.38,4.88,26.37,15.29L506,1.36h35.68ZM488.92,75.15a30.1,30.1,0,0,0,.89-6.21c0-5.76-3.55-9.09-9.31-9.09-8.86,0-11.3,8.2-12.85,15.29l-10.19,44.32c-.66,3.1-1.55,6.21-1.55,9.53,0,5.54,3.77,8.64,9.09,8.64,9.31,0,11.52-9.31,13.3-16.62Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M549.56,157.81h-33l7.76-33.91h33Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M602.53,117.26c-1.11,3.55-2.44,7.76-2.44,11.75,0,6.21,5.1,7.53,8.86,7.53,8.86,0,11.52-7.09,11.52-13.52,0-5.1-1.11-6-9.75-12.41L593.43,97.76c-8.64-6.43-13.08-13.52-13.08-21.94,0-6.87,3.32-17.07,6-21.94,6.43-11.52,21.5-17.51,40.78-17.51,23.27,0,41.22,9.09,41.22,27.26a56.21,56.21,0,0,1-1.77,12.19H633.1c.66-2.22,2-7.09,2-9.53,0-.89-.22-6.65-8.87-6.65-6.43,0-10.64,4.43-10.64,11.52,0,4,2.44,6.65,6.87,9.75l14.41,10.42c13.07,9.31,20.61,14.41,20.61,26.82,0,7.09-2.22,19.72-9.09,28.15-5.76,7.09-17.95,14-38.78,14-24.16,0-43.88-7.31-43.88-27.92a52.14,52.14,0,0,1,2.66-15.07h34.14Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M737.05,60.52H721.54l-14.41,61.61a34.49,34.49,0,0,0-.89,6.65c0,5.76,5.1,6.43,9.75,6.43h4.65l-5.1,22.6H698.25c-12.41,0-29.92-1.77-29.92-17.73,0-4,1.11-8,2-11.74l16.4-67.81h-14.4L677.2,39h14.63l8.64-37.67h34.79L726.4,39h15.51Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M814.39,157.81H778.93l2.66-12.41C773,154.71,764.3,160,751.23,160c-14.41,0-23.05-7.53-23.05-22.38A58.22,58.22,0,0,1,730,125.24L749.67,39h35.9l-18,77.56c-.89,3.77-2,7.53-2,11.3,0,5.1,3.32,8.64,8.42,8.64,9.75,0,12.41-11.52,14.18-19.06L806.18,39h35.68Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M915.23,157.81H879.55l3.55-14.4c-7.53,9.31-16,16.4-28.59,16.4-15.07,0-25.26-9.31-25.26-24.38,0-7.31,3.77-24.6,6.43-35.68l7.09-28.81c6.21-25.26,17.07-34.79,35-34.79,10.42,0,22.38,4.88,26.37,15.29L915.67,1.36h35.68ZM898.61,75.15a30.1,30.1,0,0,0,.89-6.21c0-5.76-3.55-9.09-9.31-9.09-8.86,0-11.3,8.2-12.85,15.29l-10.19,44.32c-.66,3.1-1.55,6.21-1.55,9.53,0,5.54,3.77,8.64,9.09,8.64,9.31,0,11.52-9.31,13.3-16.62Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M960.22,157.81h-35L952.46,39H987.7ZM990.36,28H955.12l6.21-26.59h35.24Z"
        transform="translate(-1.36 -1.35)"
      />
      <path
        d="M1068.15,130.55c-4.21,12.41-11.08,29.92-46.54,29.92-26.59,0-43.66-9.09-43.66-31.69,0-14.41,10.19-52.74,12-58.73,4-12.63,11.75-34.13,45.43-34.13,17.73,0,45.65,2.88,45.65,32.58C1081,80.91,1071.47,120.36,1068.15,130.55ZM1046,73.16a23.5,23.5,0,0,0,.66-4.65c0-6-4.21-8.86-9.53-8.86-9.53,0-11.3,8.2-12.63,13.52l-11.08,47.42a32.41,32.41,0,0,0-.89,6.87c0,4.65,2.66,9.09,9.53,9.09,9.53,0,11.52-7.76,13.3-16Z"
        transform="translate(-1.36 -1.35)"
      />
    </Logo>
  );
};
